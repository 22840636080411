<template>
  <div id="home-page">
    <template v-if="isError404">
      <component :is="Error404Component" />
    </template>
    <template v-else-if="isError500">
      <component :is="Error500Component" />
    </template>
    <div
      v-else-if="canvasData"
      id="canvas"
    >
      <Canvas :canvas="canvasData">
        <template
          v-if="isZorShown"
          #zone-of-relevance
        >
          <ZoneOfRelevance :zor-cards="zorCards" />
        </template>
        <template
          v-if="isRVIEnabled && !isZoneOfRelevanceEnabled"
          #recently-viewed-items
        >
          <div
            id="recently-viewed-items"
            class="grid-container"
          >
            <component
              :is="RVIComponent"
              :pros-product-pool-media="{
                componentType: 'RviCarousel',
                panelType: 'rvi',
                context: 'rvi_hpdash_zone'
              }"
              :properties="canvasData?.meta?.properties"
            />
          </div>
        </template>
      </Canvas>
    </div>
  </div>
  <BackToTop />
</template>

<script setup>
import {
  onMounted, computed, defineAsyncComponent,
} from 'vue';
import { useStore } from 'vuex';
import CacheManager from '@vue-util/cacheManager';
import { Canvas } from '@common-vue/canvas-aem';
import BackToTop from '@atomic-ui/backToTop';
import { useGoogleAdsManager } from '@common-vue/site-monetization';
import { ZoneOfRelevance } from '@components/ZoneOfRelevance';
import { SET_ZOR_INFO, SET_RVI_IDS } from '@types/mutations';
import { IS_ZONE_OF_RELEVANCE_ENABLED, GET_ZOR_CARDS } from '@types/getters';
import { FETCH_ZOR_CARDS } from '@types/actions';
import loadIshipWelcomePopup from '../utils/loadIshipContent';

const store = useStore();
const envProps = computed(() => store.state?.envProps);
const canvasData = computed(() => store.state?.pageData?.canvas);
const isZoneOfRelevanceEnabled = computed(
  () => store.getters[`zoneOfRelevanceStore/${IS_ZONE_OF_RELEVANCE_ENABLED}`],
);

// Remove the filter if Loyalty Card needs to be added to ZOR again
const zorCards = computed(
  () => store.getters[`zoneOfRelevanceStore/${GET_ZOR_CARDS}`].filter((item) => item?.cardType?.toUpperCase() !== 'LOYALTY'),
);

const isError404 = computed(() => {
  const errorExists = canvasData.value?.errors?.error || canvasData.value?.error;
  return errorExists && canvasData.value?.status >= 400 && canvasData.value?.status < 500;
});

const isError500 = computed(() => {
  const errorExists = canvasData.value?.errors?.error || canvasData.value?.error;
  return errorExists && canvasData.value?.status >= 500;
});

const isRVIEnabled = computed(() => {
  const recentlyViewedItems = CacheManager.getPersistent('recentlyViewedItems');
  return canvasData?.value?.meta?.properties?.homepageRecentlyViewedEnabled && recentlyViewedItems;
});

/* if redirection is enabled */
const isRedirectEnabled = computed(() => canvasData.value?.redirectEnabled);
if (isRedirectEnabled?.value) {
  window.location.replace(isRedirectEnabled.value);
}

/* zone of relevance */
if (isError404.value || isError500.value) {
  store.commit(`zoneOfRelevanceStore/${SET_ZOR_INFO}`, {}, { root: true });
}
if (canvasData?.value?.meta?.properties?.enableZoneOfRelevance) {
  store.commit(`zoneOfRelevanceStore/${SET_ZOR_INFO}`, canvasData.value, { root: true });
}

const tagEnv = envProps?.value?.tagMngrEnv;
const brand = envProps?.value?.BRAND;
const Error404Component = defineAsyncComponent(() => (brand === 'mcom'
  ? import('@components/mcom/Error404')
  : import('@components/bcom/Error404')));
const Error500Component = defineAsyncComponent(() => (brand === 'mcom'
  ? import('@components/mcom/Error500')
  : import('@components/bcom/Error500')));
const RVIComponent = defineAsyncComponent(() => import('@common-vue/media-components/src/components/pool/ProsProductPool'));

const isZorShown = computed(() => isZoneOfRelevanceEnabled.value && zorCards.value.length > 5);

onMounted(async () => {
  const enableGAMAdsFromApp = canvasData?.value?.meta?.properties?.enableGAMAdsFromApp || false;
  const enableGAMImpressionTags = canvasData?.value?.meta?.properties?.enableGAMImpressionTags || false;
  const enableGAMClickTags = canvasData?.value?.meta?.properties?.enableGAMClickTags || false;
  const deviceType = canvasData?.value?.meta?.context?.deviceType !== 'PHONE'
    ? 'desktop'
    : 'mobile';
  useGoogleAdsManager.initGAMTags({
    page: 'homepage',
    device: deviceType,
    enableGAMAdsFromApp,
    enableGAMImpressionTags,
    enableGAMClickTags,
    tagEnv,
    brand,
  });

  loadIshipWelcomePopup(canvasData?.value?.meta?.context, brand);

  if (isZoneOfRelevanceEnabled.value) {
    const rviIds = (localStorage.getItem('recentlyViewedItems') || '')
      .split(',')
      .filter(Boolean);
    store.commit(`zoneOfRelevanceStore/${SET_RVI_IDS}`, rviIds);

    await store.dispatch(`zoneOfRelevanceStore/${FETCH_ZOR_CARDS}`);
  }
});
</script>

<style lang="scss">
.home {
  position: inherit;
}
.product-thumbnail-container .toast {
    position: absolute;
  }
#recently-viewed-items:not(:empty) {
  padding: rem-calc(0 20px 64px);
  @include breakpoint(medium down) {
    padding: rem-calc(0 20px 48px);
  }
  .pros-collection {
    overflow: visible;
  }
}
</style>
